import React from 'react';
import { StaticImage } from 'gatsby-plugin-image';
import '../components/styles/seance.css';
import Layout from '../components/layout';
import HeaderRandom from '../components/headerRandom';
import Seo from '../components/seo';

const StopTabac = () => (
  <Layout>
    <Seo />
    <HeaderRandom />
    <div id='title'>
      <h1>Arrêt du tabac</h1>
    </div>
    <div className='division'>
      <div className='container illustration'>
        <div className='col-md-4 col-sm-4 col-xs-4 flex'>
          <StaticImage src='../img/smoking.png' width={64} height={64} className='center block' alt='smoking' />
        </div>
        <div className='col-md-4 col-sm-4 col-xs-4 flex'>
          <StaticImage src='../img/armchair.png' width={64} height={64} className='center block' alt='armchair' />
        </div>
        <div className='col-md-4 col-sm-4 col-xs-4 flex'>
          <StaticImage src='../img/no-smoking.png' width={64} height={64} className='center block' alt='no-smoking' />
        </div>
      </div>
    </div>
    <div className='container division'>
      <div className='row'>
        <div>
          <div className='col-md-12'>
            <h2 className='title'>HYPNOS’CHIAPI</h2>
          </div>
          <div className='col-md-4 sticky'>
            <StaticImage src='../img/jailsmoke.jpg' placeholder='blurred' alt='jailsmoke' />
          </div>
          <div className='col-md-8'>
            <p>
              Vous vous dites que ce n’est pas le bon moment pour arrêter de fumer,  que dans un mois ça sera le bon moment, et puis finalement dans 2 mois... Et si en fait ce n’était jamais le bon moment ? <br />
              Et s'il y avait un conflit interne, un conflit entre votre conscient et votre inconscient ? D’un côté vous vous dites "pour ma santé c’est mieux" et d’un autre côté vous vous dites "ça va pas être facile". Vous avez également pris des habitudes telles que café-cigarette, pause-cigarette, levé-cigarette, fiesta-cigarette…. Vous avez déjà essayé les patchs, gums… Sans résultats !

            </p>
          </div>
        </div>

        <div className='col-md-12 division'>
          <div className='row toggle'>
            <div className='col-md-8'>
              <p>
                <strong>
                  Aujourd'hui, 1 fumeur sur 2 meurt des conséquences du tabac. Ce fléau serait par ailleurs responsable de 73000 décès par an en France. Le tabac est la première cause de mortalité évitable !
                </strong>
              </p>
              <p>Si vous souhaitez vous libérer des chaînes de la cigarette, économiser du temps, de l'argent, pouvoir de nouveau vous satisfaire du goût des aliments, ne plus ressentir de frustration et surtout avoir une bonne santé, cela est désormais possible.</p>
            </div>
            <div className='col-md-4 sticky'>
              <StaticImage src='../img/smokedeath.jpg' placeholder='blurred' alt='runtodeath' />
            </div>
          </div>
        </div>

        <div className='col-md-12 division'>
          <div className='row'>
            <div className='col-md-4 sticky'>
              <StaticImage src='../img/stopsmoking.jpg' placeholder='blurred' alt='stopsmoking' />
            </div>
            <div className='col-md-8'>
              <p>Vous voulez être épaulé afin de vous libérer de l’emprise du tabac. Je vous propose 2 magnifiques méthodes en 1 seule séance :</p>
              <p>L’hypnose et le chiapi.</p>
              <p>L'hypnose est une discipline qui permet de parler à l'inconscient par le biais de suggestions, l'individu est alors dans une phase entre la veille et le sommeil induite par une relaxation.</p>
              <p>
                Elle permet d'accéder à des ressources internes, propre à chaque personne.
                L’hypnose traite le manque psychique, vous avez par exemple lié la cigarette à des habitudes, gestes automatiques.
              </p>
              <p>La méthode chiapi issu de l’acupuncture (technique traditionnelle chinoise) est capable de couper net le besoin physique de fumer en éliminant les symptômes de manque.</p>

            </div>
          </div>
        </div>

        <div className='col-md-12 '>
          <p>Comment ?</p>
          <p>
            Grâce à un laser d’acupuncture sur des points précis, pas besoin d’aiguilles.
            Cette technique est douce et indolore, sans danger elle peut être faite sur des femmes enceintes.
          </p>
          <p>Il n’y a donc aucune contre-indication.</p>
          <p>De plus, je vous donnerais des astuces pour éviter la prise de poids.</p>
          <p>Il s'agit au final d'un véritable coaching.</p>
          <p>Etant professionnelle de santé, je me forme régulièrement à la tabacologie.</p>
          <p>Alors prêt à être enfin libre ?</p>
        </div>

        <div className='col-md-12'>
          <p><u>Déroulement séance</u> (prévoir 2h au minimum)</p>
          <ul className='dot'>
            <li>Accueil et renseignements</li>
            <li>Recueil d’information</li>
            <li>Hypnose ou méthode Chiapi</li>
          </ul>
        </div>
      </div>
    </div>
  </Layout>
);

export default StopTabac;
